<template>
	<!-- 我的插件 -->
	<div class="admin-adhibition-my el-content">
		<div class="aam-refresh" @click="refreshCloud">
			<i class="ri-refresh-line ri-top ri-lg"></i>
			更新缓存
		</div>
        <div class="kd-pacel">列表</div>
		<a-table :pagination="false" row-key="plugin_id"
			:data-source="info.list"
			:columns="[
				{ title: 'ID', dataIndex: 'plugin_id' },
				{ title: 'logo', dataIndex: 'logo', slots: { customRender: 'logo' } },
				{ title: '插件名称', dataIndex: 'info.plugin_name' },
				{ title: '插件标识', dataIndex: 'plugin_sign' },
				{ title: '实际价格', dataIndex: 'amount_actual', slots: { customRender: 'amount_actual' } },
				{ title: '优惠价格', dataIndex: 'amount_origin', slots: { customRender: 'amount_origin' } },
				{ title: '插件简介', dataIndex: 'info.plugin_desc' },
				{ title: '状态', dataIndex: 'status', slots: { customRender: 'status' } },
				{ title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
			]"
		>
			<template #logo=" { record } " >
				<img :src="record.info.plugin_logo" alt="" style="width: 30px;height: 30px;" /> 
			</template>
			<template #amount_actual=" { record } " >
				<span>{{ record.amount_actual ? '￥' + record.amount_actual : '免费' }}</span>
			</template>
			<template #amount_origin=" { record } " > 
				<span>{{ record.amount_origin ? '￥' + record.amount_origin : '无优惠' }}</span> 
			</template>
			<template #status=" { record } " >
				<a-tooltip title="点击设置状态"> 
					<a-tag @click="setStatus(record)" :color="record.status == 1 ? '#00CC66' : '#999'">
						{{ record.status == 1 ? '启用' : '禁用' }}
					</a-tag>
				</a-tooltip> 
			</template>
			<template #action=" { record } " >
				<kd-button title="设置价格" 
					icon="ri-settings-3-line" 
					@click="toEditPirce(record)"
					v-has="{ action: 'sass_plugin_set_price' }">
				</kd-button>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange=" (page, e) => { getAdhibiton(info.page, e); } "
				@change=" e => { getAdhibiton(e, info.limit); } "
			/>
		</div>

		<a-modal title="设置价格"  
            v-model:visible="priceState.show"
            width="500px" 
            @ok="savePirceForm" 
            @cancel="show.price= false"
        >
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="实际价格">
                    <a-input v-model:value="priceState.form.amount_actual"></a-input>
                </a-form-item>
				<a-form-item label="优惠价格">
                    <a-input v-model:value="priceState.form.amount_origin"></a-input>
                </a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRaw, toRefs } from 'vue'
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				limit: 10,
				page: 1,
				count: 0
			},
		})
		getAdhibiton(1,state.info.limit)

		function getAdhibiton(page,limit){
			programModel.getMyPlugin(page,limit,res=>state.info = {limit,...res})
		}

        //设置价格
        let { priceState,toEditPirce,savePirceForm } = useSetPirce({state,getAdhibiton})

		function setStatus(row){
			let data = {
				plugin_id: row.plugin_id,
				amount_origin: row.amount_origin,
				amount_actual: row.amount_actual,
				status: row.status == 1 ? 0 : 1
			}
			programModel.updateMyPluginPirce('status',data,()=>getAdhibiton(state.info.page,state.info.limit))
		}

		const refreshCloud = ()=>programModel.cloudRefreshCache()

		return{
			...toRefs(state),
			getAdhibiton,

			refreshCloud,
			setStatus,

            priceState,
			toEditPirce,
			savePirceForm,
		}
	}
}

//设置价格
function useSetPirce(obj){
    let priceState = reactive({
        show:false,
        form: null
    })

    function toEditPirce(row){
        priceState.form = {
            plugin_id: row.plugin_id,
            status: row.status,
            amount_actual: '',
            amount_origin: '',
        }
        priceState.show = true
    }

    function savePirceForm(){
        programModel.updateMyPluginPirce('price',toRaw(priceState.form),()=>{
           priceState.show = false
            obj.getAdhibiton(obj.state.info.page,obj.state.info.limit)
        })
    }
    return{priceState,toEditPirce,savePirceForm}
}


</script>

<style lang="scss">
.admin-adhibition-my {
	.aam-refresh {
		position: absolute;
		margin-left: 90%;
		cursor: pointer;
		color: #999;
		z-index: 99;
	}
}
</style>
